<template>
  <span class="translated-text" :class="previewClassName"  v-text="preview"></span>
</template>

<script >
export default {
  name: "translated-text",
  props: ["value", "trim", "lowercase"],
  computed: {
    preview() {
      if (!this.value) {
        return "";
      }
      var previewString = this.translate(this.value);
      if (this.trim) {
        previewString = previewString.trim();
      }
      if (this.lowercase) {
        previewString = previewString.toLowerCase();
      }
      return previewString;
    },
    previewClassName() {
      if (!this.value) {
        return "";
      }

      if (typeof this.value == "string") {
        return "not-translated";
      }

      if (typeof this.value == "object") {
        if (typeof this.value[this.language] == "object" && this.value[this.language]) {
          return "translated";
        }
        return "missing-translation";        
      }
      return "some-error";
    }
  }
};
</script>
