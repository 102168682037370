<template>
  <div class="datenschutz content page">
    <div class="page-content text-content">
      <section class="page-section">
        <div class="page-section-content">
          <h1>Targeting- und Werbecookie</h1>
         

<p>
Diese Cookies sammeln Informationen über das Nutzungsverhalten der User unserer
Website, um sicherzustellen, dass nur Werbemittel präsentiert werden, die für unsere
User interessant sein könnten. Targeting- und Werbecookies können auch dazu
verwendet werden, einen erneuten Besuch unserer Website oder den Besuch einer
Website die Teil des Werbepartnernetzwerks unseres Unternehmens ist, zu erkennen.
IP-Adressen werden dabei nur anonymisiert und verschlüsselt gespeichert. Die
Auswahl an angezeigten Informationen kann basierend auf den Interessen des Users
oder auch darauf, welche Werbung dieser zuvor angeschaut hat, getroffen werden.
Zudem wird dafür gesorgt, dass Werbung korrekt angezeigt und verhindert, dass
dieselbe Werbung zu oft ausgespielt wird. Auch die Messung der Effizienz von
Werbekampagnen erfolgt mittels dieser Cookies. Die Speicherdauer dieser Cookies
beträgt drei Monate. Um die von Ihnen gespeicherten anonymisierten Daten ein zu
sehen bzw diese zu löschen (Opt Out), benutzen sie bitte folgenden Link: <a href="https://site.adform.com/privacy-center/plaPorm-privacy/opt-out/">https://site.adform.com/privacy-center/plaPorm-privacy/opt-out/</a>
</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "datenschutz",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
};
</script>

<style>
</style>
