<template>
  <div
    class="validated-input input-view"
    :class="{
      'has-errors': errors && errors.length > 0,
      [type]: true,
      postfix: postfix,
      'label-behind-input': showLabelBehindInput,
    }"
  >
    <label class="input-label-container">
      <span
        v-if="label && !showLabelBehindInput"
        class="label"
        v-html="label"
      ></span>
      <div class="input-element-container">
        <span
          v-if="
            isFileUpload && placeholder && (!localValue || !localValue.length)
          "
          class="placeholder"
          v-html="placeholder"
        ></span>
        <textarea
          v-if="isTextarea"
          :placeholder="placeholder"
          :name="name"
          v-model="localValue"
          :disabled="disabled"
        ></textarea>
        <input
          v-if="isInput"
          :type="type"
          :placeholder="placeholder"
          :name="name"
          v-model="localValue"
          :disabled="disabled"
        />
        <input
          v-if="isCheckbox"
          type="checkbox"
          :placeholder="placeholder"
          :name="name"
          v-model="localValue"
          :disabled="disabled"
        />
        <input
          v-if="isFileUpload"
          type="file"
          :multiple="multiple"
          :placeholder="placeholder"
          :name="name"
          :disabled="disabled"
          @change="
            filesChange($event.target.name, $event.target.files);
            fileCount = $event.target.files.length;
          "
        />
        <v-select
          v-if="isSelect"
          :name="name"
          v-model="localValue"
          :options="options"
          :placeholder="placeholder"
          :disabled="disabled"
          :get-option-label="getOptionLabel"
        ></v-select>
        <span v-if="postfix" class="postfix" v-html="postfix"></span>
        <div
          class="previews"
          v-if="isFileUpload && localValue && localValue.length > 0"
        >                          
          <div class="preview" v-for="preview of previews" :key="preview.text">
            <img class="preview-image" v-if="preview.image" :src="preview.image" />
            <div class="preview-text" v-if="!preview.image">{{ preview.text }}</div>
          </div>
        </div>
      </div>
      <span
        v-if="label && showLabelBehindInput"
        class="label"
        v-html="label"
      ></span>
    </label>

    <div class="validation-errors" v-if="errors && errors.length">
      <div
        class="validation-error"
        v-for="(error, errorIndex) of errors"
        :key="'error-' + errorIndex"
        v-text="error"
      ></div>
    </div>
  </div>
</template>

<script >
const VueSelect = require("vue-select").VueSelect;

export default {
  name: "validated-input",
  props: [
    "placeholder",
    "value",
    "name",
    "type",
    "rules",
    "label",
    "disabled",
    "postfix",
    "options",
    "optionLabel",
    "multiple",
  ],
  components: { "v-select": VueSelect },
  data: function () {
    return {
      localValue: null,
      previews: null,
      errors: [],
    };
  },
  computed: {
    showLabelBehindInput() {
      return this.isCheckbox;
    },
    isTextarea() {
      return this.type == "textarea";
    },
    isCheckbox() {
      return this.type == "checkbox";
    },
    isFileUpload() {
      return this.type == "file";
    },
    isSelect() {
      return this.type == "select";
    },
    isInput() {
      return (
        !this.isTextarea &&
        !this.isCheckbox &&
        !this.isSelect &&
        !this.isFileUpload
      );
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (newValue, oldValue) {
        if (
          typeof this.localValue !== typeof this.value ||
          this.localValue != this.value
        ) {
          this.localValue = this.value;
        }
      },
    },
    localValue: {
      handler: function (newValue, oldValue) {
        this.$emit("input", this.localValue);
      },
    },
  },
  mounted() {},
  methods: {
    getDataForValidation() {
      return this.localValue;
    },
    getOptionLabel(option) {
      if (option.label) {
        return option.label;
      }
      if (this.optionLabel) {
        return this.optionLabel(option);
      }
      return option;
    },
    async filesChange(fieldName, fileList) {
      if (!fileList.length) {
        this.localValue = null;
        this.previews = null;
        return;
      }
      this.localValue = Array.from(fileList);
      this.previews = {};
      function getBase64(file) {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (ev) => {
            resolve(ev.target.result);
          };
          reader.readAsDataURL(file);
        });
      }
      for (let file of this.localValue) {    
        let preview = {
          text: file.name,
          image: null
        }
        if(file.type.startsWith('image/')){
          preview.image = await getBase64(file);       
        }        
        this.$set(this.previews, file.name, preview);
        
      }
       
      //  let old = this.previews;
      //  this.previews = null;
      //  this.previews = old;
       
    },
  },
};
</script>
