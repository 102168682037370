import Vue from 'vue'
import App from './App.vue'

import VueConfig from "vue-config";

let options = {
  firebaseApiKey: "AIzaSyBqI_OzIjj8n4FpQc3vLKLw3EgxTIFBjRM",
  firebaseProjectId: "bombastic-challenge",
  url: "https://challenge.schartnerbombe.at",
  apiUrl: "https://challenge.schartnerbombe.at",
  cdnUrl: "https://challenge-cdn.schartnerbombe.at",  
};


if (location.hostname === 'localhost') {
  options.url = "http://localhost:5000";
  options.apiUrl = "http://localhost:5000";  
  console.log('options', options)
}

Object.assign(Vue.config, options);
Vue.use(VueConfig, Vue.config);
Vue.config.productionTip = false;


import VueResource from "vue-resource";
Vue.use(VueResource);

import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer, {
  lang: 'en'
})

import 'vue-select/dist/vue-select.css';

var store = require("./store").default;


import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth';
import 'firebase/functions';


// Get a Firestore instance
firebase.initializeApp({
  apiKey: Vue.config.firebaseApiKey,
  projectId: Vue.config.firebaseProjectId
})

if (location.hostname === 'localhost') {
  console.log('using firebase emulators')
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.functions().useEmulator("localhost", 5001);
  firebase.auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
}


//must use require becauese imports  seem to be resorted and will be executed before firebase is initialized
let router = require("./router").default;

/**
 * set up localisation and translations
 */
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

/**
 * create some computed properties which should be available everywhere
 */
Vue.mixin({
  computed: {
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods:{
  
  }
});


let translations = {
  "de":  require("./translations/de"),
  "en":  require("./translations/en"),
  "es":  require("./translations/es")
};


const i18n = new VueI18n({
  locale: 'de',
  messages: translations,
})

import elementPlugin from "../../backend/src/elements";
Vue.use(elementPlugin);


import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)


new Vue({i18n, router,store, render: h => h(App) }).$mount("#app");

