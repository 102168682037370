import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)







export default new Vuex.Store({
  state: {
    submissions: null,
    submissionsById: {}
  },
  mutations: {
    setSubmissions(state, submissions) {      
      state.submissions = submissions;
      state.submissionsById = {};
      if(state.submissions){
        for(let submission of state.submissions){
          state.submissionsById[submission.id] = submission
        }
      }
      
    }
    
  },
  actions: {
    setSubmissions({ commit }, submissions) {
      commit("setSubmissions", submissions);
      
    } ,   
    async loadSubmissions({dispatch}){
      
      try {
        let result = await Vue.http.get(
          Vue.config.apiUrl + "/api/submissions/"
        );
        dispatch('setSubmissions', result && result.body ? result.body : null);
      } catch (error) {
        dispatch('setSubmissions', null);
        console.log("error", error);
      }

    }
  },
  getters: {
    getSubmissions: state => {
      return state.submissions;
    },
    getSubmissionById: state => {
      return function(id){
        return state.submissionsById[id] || null;

      }
    }

  },
  modules: {
    
  }

})
