<template>
  <div class="impressum content page">
    <div class="page-content text-content">
      <section class="page-section">
        <div class="page-section-content text-content">
          <h1 class="page-title">Impressum</h1>

          <table>
            <tbody>
              <tr>
                <td style="width: 30%">Firmenwortlaut:</td>
                <td style="width: 70%">Starzinger GmbH &amp; Co KG</td>
              </tr>
              <tr>
                <td>Staße:</td>
                <td>Bahnhofstraße 1</td>
              </tr>
              <tr>
                <td>PLZ, Ort:</td>
                <td>A-4890 Frankenmarkt</td>
              </tr>
              <tr>
                <td>Tel:</td>
                <td>+43 7684 6444 0</td>
              </tr>
              <tr>
                <td>Fax:</td>
                <td>+43 7684 6444 19</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>
                  <a href="mailto:office@starzinger.at">office@starzinger.at</a>
                </td>
              </tr>
              <tr>
                <td>Website:</td>
                <td>www.starzinger.at</td>
              </tr>
              <tr>
                <td>Rechtsform:</td>
                <td>
                  <p class="bodytext">Gesellschaft mit beschränkter Haftung</p>
                </td>
              </tr>
              <tr>
                <td>Firmenbuch Nr.:</td>
                <td>FN 223140 y</td>
              </tr>
              <tr>
                <td>Firmenbuchgericht:</td>
                <td>Wels</td>
              </tr>
              <tr>
                <td>Geschäftsführer:</td>
                <td>
                  Herr Kommerzialrat August Starzinger &amp; Frau Ludmilla
                  Starzinger
                </td>
              </tr>
              <tr>
                <td>Prokurist(en):</td>
                <td>Herr Wilhelm Wiesbauer, Prok. Mag. Dr. Patrick Moser</td>
              </tr>
              <tr>
                <td>UID:</td>
                <td>ATU54219402</td>
              </tr>
              <tr>
                <td>Ara-Licence No.:</td>
                <td>2700</td>
              </tr>
              <tr>
                <td>Allg. Geschäftbedinungen:</td>
                <td>
                  <a
                    href="https://www.schartnerbombe.at/de/agb/"
                  >
                    zu den AGBs</a
                  >
                </td>
              </tr>
              <tr>
                <td>Eigentümerstruktur:</td>
                <td>100% österreichisches Familienunternehmen</td>
              </tr>
              <tr>
                <td>Rechnungslegung-Grundsatz:</td>
                <td>GAAP (Generally Accepted Accounting Principles)</td>
              </tr>
              <tr>
                <td>Bankverbindung:</td>
                <td>
                  Raiffeisenbank Pöndorf-Frankenmarkt<br />
                  Konto-Nr.: 232.801<br />
                  BLZ: 34434<br />
                  IBAN: AT293443400000232801<br />
                  BIC: RZOOAT2L434
                </td>
              </tr>
              <tr>
                <td>Bankverbindung:</td>
                <td>
                  Sparkasse Frankenmarkt AG<br />
                  Konto-Nr.: 1834<br />
                  BLZ: 20306<br />
                  IBAN: AT742030600000001834<br />
                  BIC: SPFRAT21
                </td>
              </tr>
              <tr>
                <td>Bankverbindung:</td>
                <td>
                  <p class="bodytext">
                    Bank Austria<br />
                    Konto-Nr.: 6923 215 500<br />
                    BLZ: 12000<br />
                    IBAN: AT79 1100 0069 2321 550<br />
                    BIC: BKAUATWW
                  </p>
                </td>
              </tr>
            </tbody>
          </table>



<h2>Targeting- und Werbecookie</h2>
<p>
Diese Cookies sammeln Informationen über das Nutzungsverhalten der User unserer
Website, um sicherzustellen, dass nur Werbemittel präsentiert werden, die für unsere
User interessant sein könnten. Targeting- und Werbecookies können auch dazu
verwendet werden, einen erneuten Besuch unserer Website oder den Besuch einer
Website die Teil des Werbepartnernetzwerks unseres Unternehmens ist, zu erkennen.
IP-Adressen werden dabei nur anonymisiert und verschlüsselt gespeichert. Die
Auswahl an angezeigten Informationen kann basierend auf den Interessen des Users
oder auch darauf, welche Werbung dieser zuvor angeschaut hat, getroffen werden.
Zudem wird dafür gesorgt, dass Werbung korrekt angezeigt und verhindert, dass
dieselbe Werbung zu oft ausgespielt wird. Auch die Messung der Effizienz von
Werbekampagnen erfolgt mittels dieser Cookies. Die Speicherdauer dieser Cookies
beträgt drei Monate. Um die von Ihnen gespeicherten anonymisierten Daten ein zu
sehen bzw diese zu löschen (Opt Out), benutzen sie bitte folgenden Link: <a href="https://site.adform.com/privacy-center/plaPorm-privacy/opt-out/">https://site.adform.com/privacy-center/plaPorm-privacy/opt-out/</a>
</p>
           
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "impressum",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
};
</script>

<style>

.impressum table{
  margin-bottom: 2rem;
}
.impressum table td {
  
  padding: 0.5rem;
}
.impressum table td:first-child {
  font-weight: bold;
}
</style>
