<template>
  <div class="preise content page">
    <div class="page-content text-content">
      <section class="page-section">
        <div class="page-section-content">
          <h1 class="page-title">DAS KANNST DU GEWINNEN!</h1>

           <p>
            Unter den Teilnehmern/ Teilnehmerinnen der Schartner Bombe
            Arschbombenchallenge 2021 wird der Gewinner/die Gewinnerin von einer
            Jury ermittelt.
          </p>


          <div class="teaser-image">
            <img src="frontend/images/Preise.png" />
          </div>
         
          <p>
            Der <em>Hauptpreis ist der neue Fiat 500 Elektro (mittlere
            Ausstattung)</em>.<br />
            Außerdem wird <em>an den zweiten und dritten Platz eines von 2
            KTM MOUTAINBIKES</em> verlost.
          </p>
          <p>
            <i>Die Preise sind nicht in bar ablösbar.</i>
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "preise",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
};
</script>

<style>
.teaser-image{
  padding-bottom: 2rem;
}
.teaser-image img {
  width: 100%;
  max-width: 300px;
  display: block;
  margin: 0 auto;
  position: relative;
  left: -0.4rem;
}
</style>
