<template>
  <div class="teilnahmebedingungen content page">
    <div class="page-content text-content">
      <section class="page-section">
        <div class="page-section-content text-content">
          <h1 class="page-title">Teilnahmebedingungen</h1>
          <p>
            Es gelten die nachstehenden Teilnahmebedingungen des Veranstalters
            Starzinger GmbH & Co KG, die mit der Teilnahme an der „Arschbomben
            Challenge 2021“ akzeptiert werden. Der
            <em
              >Hauptpreis ist der neue Fiat 500 Elektro (mittlere
              Ausstattung)</em
            >. Außerdem wird
            <em
              >an den zweiten und dritten Platz eines von 2 KTM MOUTAINBIKES</em
            >
            verlost. Die „Schartner Bombe – Arschbomben Challenge 2021“ findet
            vom 01.06.2021, 0.00 Uhr, bis 30.09.2021, 24.00 Uhr, statt.
          </p>
          <p>
            In diesem Zeitraum kann pro Teilnehmer/-in ein Foto oder Video von
            einer Arschbombe (einem Sprung ins Wasser) via der Internetplattform
            <a href="http://challenge.schartnerbombe.at"
              >http://challenge.schartnerbombe.at</a
            >
            eingereicht werden. Sollte es technisch nicht möglich sein, das Foto
            oder Video hochzuladen, steht es dem/der Teilnehmer/-in frei,
            sein/ihr Foto oder Video via E-Mail an
            <a href="mailt:challenge@starzinger.at">challenge@starzinger.at</a>
            zu schicken, um mitzumachen.
          </p>
          <p>
            Es sind übliche Dateiformate zu verwenden, wobei Fotos eine
            Mindestbreite von 480 Pixel aufweisen müssen und die Größe der Datei
            bei einem Foto 5 MB und bei einem Video 100 MB nicht übersteigen
            darf. Jede/-r Teilnehmer/-in darf nur ein Foto oder Video einsenden.
            Teilnahmeberechtigt sind alle Teilnehmer/-innen ab 16 Jahren oder
            mit Zustimmung eines Erziehungsberechtigten. Der/die Teilnehmer/-in
            muss in Österreich wohnhaft sein. Die Preise sind nicht in bar
            ablösbar. Für Verletzungen oder Sachschäden wird keine Haftung
            übernommen.
          </p>
          <p>
            Die Teilnahme ist unverbindlich, freiwillig und kostenlos. Durch die
            Teilnahme entstehen keine Rechtsansprüche, Schartner Bombe bzw.
            Starzinger GmbH & Co KG übernehmen keine Haftung.
          </p>
          <p>
            Der Veranstalter behält sich ausdrücklich das Recht vor, einzelne
            Personen von der Teilnahme auszuschließen, wenn gegen die
            Teilnahmebedingungen und/oder geltende rechtliche Bestimmungen
            und/oder die guten Sitten verstoßen wird.
          </p>
          <p>
            Der Veranstalter ist berechtigt, die eingesandten Fotos und/oder
            Videos, sowie die Namen der Teilnehmer im Internet und in anderen
            Medien zu veröffentlichen. Die Nutzungsrechte an den eingesandten
            Fotos und Videos gehen auf den Veranstalter über.
          </p>
          <p>
            Der/die Gewinner/-innen stimmen zu, dass die bei Preisübergabe
            gemachten Fotos oder Videos, sowie die Namen der Teilnehmer zu
            Werbezwecken verwendet und veröffentlicht werden. Auch hier gehören
            wieder die Namen dazu.
          </p>
          <p>
            Der Veranstalter behält sich vor, eingesandte Fotos oder Videos, die
            ethisch nicht korrekt sind, nicht zu veröffentlichen und somit von
            der Teilnahme auszuschließen. Mitarbeiter von Schartner Bombe sind
            von der Teilnahme ausgeschlossen. Der Rechtsweg ist ausgeschlossen.
            Über das Gewinnspiel wird kein Schriftverkehr geführt. Die
            Gewinner/-innen werden per E-Mail verständigt.
          </p>

          <h2>VORZEITIGE BEENDIGUNG DES GEWINNSPIELS</h2>

          <p>
            Der Veranstalter behält sich vor, die „Schartner Bombe – Arschbomben
            Challenge 2021“ ohne Vorankündigung oder Angabe von Gründen
            jederzeit abzubrechen oder zu beenden. Die „Schartner Bombe –
            Arschbomben Challenge 2021“ wird insbesondere dann vorzeitig
            beendet, wenn eine ordnungsgemäße Durchführung aus technischen (z.
            B. Fehler oder Manipulation, welche die eingesetzte Hard- und/oder
            Software betreffen) oder rechtlichen Gründen nicht gewährleistet
            werden kann. Resultiert eine vorzeitige Beendigung aus einem
            Verhalten eines/-r Teilnehmers/-in oder sonstiger Dritter, kann der
            Veranstalter von diesem/-r Schadensersatz verlangen.
          </p>
          <h2>DATENSCHUTZ</h2>
          <p>
            Die Teilnahme an der „Schartner Bombe – Arschbomben Challenge 2021“
            erfordert die Eingabe von Vor- und Nachnamen, der persönlichen
            E-Mail-Adresse und der Postadresse. Die Einsender/-innen von
            Fotos/Videos stimmen bis auf Widerruf zu, Informationen der Marken
            Schartner Bombe (Starzinger GmbH & Co KG) per E-Mail zu erhalten.
            Diese verpflichten sich, die gesetzlichen Bestimmungen zum
            Datenschutz einzuhalten. Die zur Ermittlung der Gewinner benötigten
            Daten werden von Schartner Bombe im Rahmen der Gewinnverständigung
            erhoben und streng vertraulich behandelt.
          </p>
          <h2>UND WERBECOOKIES</h2>
          <p>
            Diese Cookies sammeln Informationen über das Nutzungsverhalten der
            User unserer Website, um sicherzustellen, dass nur Werbemittel
            präsentiert werden, die für unsere User interessant sein könnten.
            Targeting- und Werbecookies können auch dazu verwendet werden, einen
            erneuten Besuch unserer Website oder den Besuch einer Website, die
            Teil des Werbepartnernetzwerks unseres Unternehmens ist, zu
            erkennen. IP-Adressen werden dabei nur anonymisiert und
            verschlüsselt gespeichert. Die Auswahl an angezeigten Informationen
            kann basierend auf den Interessen des Users oder auch darauf, welche
            Werbung dieser zuvor angeschaut hat, getroffen werden. Zudem wird
            dafür gesorgt, dass Werbung korrekt angezeigt und verhindert, dass
            dieselbe Werbung zu oft ausgespielt wird. Auch die Messung der
            Effizienz von Werbekampagnen erfolgt mittels dieser Cookies. Die
            Speicherdauer dieser Cookies beträgt drei Monate. Um die von Ihnen
            gespeicherten anonymisierten Daten ein zu sehen bzw diese zu löschen
            (Opt Out), benutzen sie bitte folgenden Link:
            <a
              href="https://site.adform.com/privacy-center/plaRorm-privacy/opt-out/"
              >https://site.adform.com/privacy-center/plaRorm-privacy/opt-out/</a
            >
          </p>
          <p>
            Falls sie Cookies von Google bzw anderen Anbietern löschen wollen,
            klicken sie hier:
            <a href="optout.aboutads.info/?c=2#!/"
              >optout.aboutads.info/?c=2#!/</a
            >
          </p>

          <h2>SONSTIGES</h2>

          <p>
            Es gilt ausschließlich österreichisches Recht. Sollten einzelne
            dieser Bestimmungen ungültig sein oder werden, bleibt die Gültigkeit
            der übrigen Teilnahmebedingungen hiervon unberührt.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "teilnahmebedingungen",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
};
</script>

<style>
</style>
