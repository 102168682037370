<template>
  <form class="validated-form" @submit.stop.prevent="submit" ref="form">
    <slot></slot>
  </form>
</template>

<script >
import Validator from "validatorjs";
export default {
  name: "validated-form",
  mounted() {},
  computed: {
    enabledLanguages() {
      return this.$store.getters.getEnabledLanguages;
    },
  },

  methods: {
    submit() {
      let isValid = this.validate();
      if (isValid) {
        this.$emit("submit");
      }
    },
    resetErrors() {
      this.$slots.default.forEach((vNode) => {
        if (vNode.componentInstance) {
          vNode.componentInstance.errors = [];
        }
      });
    },
    validate() {
      this.resetErrors();
      let isValid = true;
      let data = {};
      let rules = {};

      let allChildren = this.$el.querySelectorAll(".validated-input");
      let inputMap = {};
      for (let childNode of allChildren) {
        let multilingualParent = childNode.closest(".multilingual-input");
        if (multilingualParent) {
          inputMap[multilingualParent.__vue__.$props.name] =
            multilingualParent.__vue__;
        } else {
          inputMap[childNode.__vue__.$props.name] = childNode.__vue__;
        }
      }

      console.log("inputMap", inputMap);

      for (let elementKey in inputMap) {
        let componentInstance = inputMap[elementKey];

        //ignore component instances without rules
        if (!componentInstance.$options.propsData.rules) {
          continue;
        }

        //warn about component instances with rules  but without names
        if (
          componentInstance.$options.propsData.rules &&
          !componentInstance.$options.propsData.name
        ) {
          alert(
            "WARNING: validated element with rule but no name, will be ignored"
          );
          continue;
        }

        let name = componentInstance.$options.propsData.name;
        let componentTag = componentInstance.$vnode.componentOptions.tag;
        if (componentTag == "multilingual-input") {
          for (let language of this.enabledLanguages) {
            let componentRules = componentInstance.$options.propsData.rules.split(
              "|"
            );

            if (componentRules.includes("required")) {
              let otherLangs = this.enabledLanguages.map(
                (lang) => name + "." + lang
              );
              //remove current language
              otherLangs.splice(otherLangs.indexOf(name + "." + language), 1);
              let specialRequiredRule =
                "required_without_all:" + otherLangs.join(",");
              let indexOfRequired = componentRules.indexOf("required");
              componentRules[indexOfRequired] = specialRequiredRule;
            }
            //make specific rules for each language: e.g. name.es
            rules[name + "." + language] = componentRules.join("|");
          }
        } else {
          rules[name] = componentInstance.$options.propsData.rules;
        }

        data[name] = componentInstance.getDataForValidation();
      }

      let customAttributeNames = {};

      for (let key in rules) {
        let keyParts = key.split(".");

        let token = "validator-custom-attribute-" + keyParts[0];
        let translatedLanguageToken =
          "validator-attribute-language-" + keyParts[1];
        let translatedLanguage = this.$t(translatedLanguageToken);
        translatedLanguage =
          translatedLanguage != translatedLanguageToken
            ? translatedLanguage
            : keyParts[1];
        let replacements =
          keyParts.length > 1 ? { language: translatedLanguage } : {};
        let translatedKey = this.$t(token, replacements);
        if (translatedKey != token) {
          customAttributeNames[key] = translatedKey;
        }
      }

      // console.log("data, rules", data, rules);

      let customMessages = {
        required: this.$t("validator-required"),
        required_without_all: this.$t("validator-required"),
      };

      let validation = new Validator(data, rules, customMessages);

      validation.setAttributeNames(customAttributeNames);

      if (validation.fails()) {
        let errors = validation.errors.errors;
        isValid = false;
        let errorsPerElement = {};
        for (let errorKey in errors) {
          let keySegments = errorKey.split(".");
          let baseSegment = keySegments[0];
          let languageSegment = keySegments.length > 1 ? keySegments[1] : null;
          if (!languageSegment) {
            errorsPerElement[baseSegment] = errors[errorKey];
          } else {
            if (!errorsPerElement[baseSegment]) {
              errorsPerElement[baseSegment] = {};
            }
            errorsPerElement[baseSegment][languageSegment] = errors[errorKey];
          }
        }

        for (let elementKey in inputMap) {
          let componentInstance = inputMap[elementKey];
          componentInstance.errors = errorsPerElement[componentInstance.$options.propsData.name];
        }
      }
      return isValid;
    },
  },
};
</script>