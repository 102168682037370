<template>
  <div class="spinner spinner-view" :class="{'big': (typeof big == 'string') || big}" >        
    <div class="spinner-icon"  v-if="spinning" ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <span class="label" v-if="label" v-text="label"></span>      
  </div>
</template>

<script >

export default {
  name: "spinner-view",
  props: ["label", "spinning", "big"],
  data: function() {
    return {
    };
  },
  watch: {
    
  },
  mounted() {},
  methods: {
    clicked(e){
      this.$emit('click',e);
    }
    
  }
};
</script>
<style scoped>

.spinner-icon {
  color: official;
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  
}
.spinner-icon div {
  transform-origin: 8px 8px;
  animation: spinner-icon 1.2s linear infinite;
}
.spinner-icon div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: 7.5px;
  width: 1px;
  height: 4px;
  border-radius: 20%;
  background: #999;
}
.spinner-icon div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.spinner-icon div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.spinner-icon div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.spinner-icon div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.spinner-icon div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.spinner-icon div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.spinner-icon div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.spinner-icon div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.spinner-icon div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.spinner-icon div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.spinner-icon div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.spinner-icon div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes spinner-icon {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}



 .spinner-view.big .spinner-icon {
  width: 32px;
  height: 32px;  
}
.spinner-view.big .spinner-icon div {
  transform-origin: 16px 16px!important;
}

.spinner-view.big .spinner-icon div:after {
  left: 15px!important;
  width: 2px!important;
  height: 8px!important;  
  background: rgba(0, 0, 0, 0.5);
}


</style>