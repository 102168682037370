<template>
  <div class="cookie-consent" v-if="showCookieConsent">
    <div class="content text-content">
      <span class="cookie-icon">🍪</span>
      <div class="label">
        Um Ihnen auf dieser Website das bestmögliche Service bieten zu können,
        speichern wir Informationen über Ihren Besuch in Cookies. Mehr dazu
        finden Sie <a href="/datenschutz">hier</a>.
      </div>
    </div>
    <span class="actions">
      <button-view label="Ablehnen" @click="reject"></button-view>
      <button-view label="Zustimmen" @click="accept"></button-view>
    </span>
  </div>
</template>

<script>
import ButtonView from "../../../backend/src/elements/ButtonView.vue";
export default {
  name: "cookie-consent",
  components: { ButtonView },
  data() {
    return {
      usersChoice: null,
    };
  },
  mounted() {
    this.usersChoice = null;
    if (window.localStorage.getItem("cookie-consent") === "no") {
      this.usersChoice = false;
    }
    if (window.localStorage.getItem("cookie-consent") === "yes") {
      this.usersChoice = true;
    }

    // this.usersChoice = null;
    // window.localStorage.removeItem("cookie-consent");

    if (this.usersChoice) {
      this.activateTrackingCode();
    }
  },
  computed: {
    showCookieConsent() {
      if (this.usersChoice === null) {
        return true;
      }
      return false;
    },
  },
  methods: {
    activateTrackingCode() {
      window._adftrack = Array.isArray(window._adftrack)
        ? window._adftrack
        : window._adftrack
        ? [window._adftrack]
        : [];
      window._adftrack.push({
        HttpHost: "track.adform.net",
        pm: 675270,
        divider: encodeURIComponent("|"),
        pagename: encodeURIComponent("ABC"),
      });
      (function () {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://s2.adform.net/banners/scripts/st/trackpoint-async.js";
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      })();
    },
    trackConversion() {        
      let shouldTrack = window.localStorage.getItem("cookie-consent") === "yes";
      if (shouldTrack) {
        window._adftrack = Array.isArray(window._adftrack)
          ? window._adftrack
          : window._adftrack
          ? [window._adftrack]
          : [];
        window._adftrack.push({
          HttpHost: "track.adform.net",
          pm: 675270,
          divider: encodeURIComponent("|"),
          pagename: encodeURIComponent("ABC Lead"),
        });
        (function () {
          var s = document.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src =
            "https://s2.adform.net/banners/scripts/st/trackpoint-async.js";
          var x = document.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        })();
      }
    },
    reject() {
      this.usersChoice = false;
      window.localStorage.setItem("cookie-consent", "no");
    },
    accept() {
      this.usersChoice = true;
      window.localStorage.setItem("cookie-consent", "yes");
      this.activateTrackingCode();      
      if(this.$route.name == 'participate'){
          this.trackConversion();
      }
    },
  },
};
</script>

<style>
.cookie-consent {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fdfacb;
  background-color: #ffef00;
}
.cookie-consent .content {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}
.cookie-consent .cookie-icon {
  font-size: 3rem;
  margin-right: 1rem;
}
.cookie-consent .label {
}
.cookie-consent .actions {
  flex-basis: 100%;
  text-align: center;
}
.cookie-consent .actions .button-view {
  font-size: 0.8rem;
  margin: 0.3rem 0.7rem;
}

@media (max-width: 500px) {
  .cookie-consent {
    padding-top: 0;
  }
  .cookie-consent .content {
    flex-wrap: wrap;
    font-size: 0.8rem;
    text-align: center;
  }
  .cookie-consent .actions .button-view {
    margin: 0.1rem 0.5rem;
    padding: 0.3rem 0.6rem;
  }
}
</style>
