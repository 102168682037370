var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home page"},[_c('div',{staticClass:"page-content"},[_vm._m(0),_c('section',{staticClass:"page-section submissions-section"},[_c('div',{staticClass:"page-section-content"},[_c('div',{staticClass:"submissions"},_vm._l((_vm.filteredSubmissions),function(submission){
var _obj;
return _c('div',{key:submission.id,staticClass:"submission",class:submission.type},[_c('router-link',{staticClass:"background-link",attrs:{"to":{ name: 'submission', params: { id: submission.id } }}}),_c('div',{staticClass:"media-container",class:( _obj = {
                'is-placeholder': submission.thumbnail,
                'has-video': submission.video
              }, _obj[submission.type] = true, _obj )},[(submission.video)?_c('img',{staticClass:"video-indicator",attrs:{"src":"frontend/images/icons/play-orange.svg"}}):_vm._e(),(submission.thumbnail)?_c('img',{staticClass:"thumbnail",attrs:{"src":submission.thumbnail}}):_vm._e()]),_c('div',{staticClass:"submission-body"},[_c('div',{staticClass:"name",domProps:{"textContent":_vm._s(submission.name)}}),(false && submission.note)?_c('blockquote',{staticClass:"note",domProps:{"textContent":_vm._s(submission.note)}}):_vm._e()]),(false)?_c('div',{staticClass:"submission-footer"},[(true || (submission.votes && submission.votes > 0))?_c('span',{staticClass:"votings",domProps:{"textContent":_vm._s(
                  (submission.votes ? submission.votes : 0) +
                  (submission.votes && submission.votes == 1
                    ? ' Vote'
                    : ' Votes')
                )}}):_vm._e()]):_vm._e()],1)}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-section intro-section"},[_c('div',{staticClass:"intro text-content page-section-content"},[_c('h2',[_vm._v(" SPLASH IN"),_c('br'),_vm._v(" AND WIN! ")])])])}]

export { render, staticRenderFns }