module.exports = {
    "page-header-image-alt-text": "Schartner Arschbomben Challenge",
    "tabview-title-newest": "Start",
    "tabview-title-submissions": "Einträge",
    "tabview-title-preise": "Preise",
    "tabview-title-participate": "Mitmachen",
    "participate-button-label": "Eintrag einreichen!",
    "participate-input-label-name": "Dein Name",
    "participate-input-placeholder-name": "Max Mustermann...",
    "participate-input-label-email": "Deine Emailadresse",
    "participate-input-placeholder-email": "max@mustermann.com",
    "participate-input-label-note": "Deine Meinung",
    "participate-input-placeholder-note": "Deine Anmerkung",
    "participate-input-label-file": "Dein Bild oder Video",
    "participate-input-placeholder-file": "Wähle dein Bild oder Video",
    "validator-required": "erforderlich",
    "participate-error": "Jetzt ist schon wieder was passiert. Bitte probier es nochmals.",
    "participate-email-exists-error": "Mit dieser Emailadresse wurde schon teilgenommen!",
    "participate-success-message": "Du erhältst in Kürze eine Email mit einem Bestätigungslink. Bitte klicke diesen um deine Teilnahme abzuschließen!",
    "email-verifcation-error-message": "Der Link ist nicht gültig.",
    "email-verifcation-success-message": "Deine Emailadresse wurde erfolgreich verifiziert und deine Einreichung wird demnächst von uns überprüft!",
    "email-verifcation-verifying-message": "Überprüfe deine Emailadresse...",
    "participate-input-label-accept-terms":"Ich aktzeptiere die <a target='_blank' href='/teilnahmebedingungen'>Teilnahmebedingungen</a>.",
    "dashboard.settings.autoplay": "Autoplay",
    "dashboard.settings.loop": "Loop",
    "dashboard.settings.speed": "Speed",
    "dashboard.settings.resolution": "Resolution",
    "dashboard.btn.back": "Back",
    "layers.loading.msg": "Loading...",
    "dashboard.btn.fullscreen": "Fullscreen",
    "dashboard.btn.play": "Play",
    "dashboard.btn.pip": "Pip",
    "layers.error.title": "Error",
    "dashboard.btn.pause": "Pause",
    "voting-widget-vote-label": "Vote for it ...",
    "vote-form-input-label-email": "Deine Emailadresse",
    "vote-form-input-placeholder-email": "max@mustermann.com",
    "vote-form-vote-button-label": "Vote!",
    "vote-email-already-voted-error": "Du hast in den letzten 24 Stunden schon für den Eintrag gevotet!",
    "vote-error": "Jetzt ist schon wieder was passiert. Bitte probier es nochmals.",
    "vote-verifcation-error-message": "Der Link ist nicht gültig.",
    "vote-verifcation-success-message": "Dein Voting wurde erfolgreich verifiziert!",
    "vote-verifcation-verifying-message": "Überprüfe deine Emailadresse...",
    "vote-success-message": "Du erhältst in Kürze eine Email mit einem Bestätigungslink. Bitte klicke diesen um dein Voting abzuschließen!",

    
};