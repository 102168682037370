
import dayjs from "dayjs";
import ValidatedInput from "./ValidatedInput.vue";
import ValidatedForm from "./ValidatedForm.vue";
import ButtonView from "./ButtonView.vue";
import Spinner from "./Spinner.vue";
import TranslatedText from "./TranslatedText.vue";
import MultillingualInput from "./MultillingualInput.vue";

export default {
  install(Vue, options) {
    Vue.component("validated-form", ValidatedForm);
    Vue.component("validated-input", ValidatedInput);
    Vue.component("button-view", ButtonView);
    Vue.component("spinner", Spinner);
    Vue.component("translated-text", TranslatedText);
    Vue.component("multilingual-input", MultillingualInput);
    
  
    Vue.filter('datetime', function(value) {
      if (value) {
        if(value.toDate){
          value = value.toDate();
        }        
        return dayjs(value).format('DD.MM.YYYY HH:mm');
      }
    });

    Vue.filter('date', function(value) {
      if (value) {
        if(value.toDate){
          value = value.toDate();
        }        

        return dayjs(String(value)).format('DD.MM.YYYY');
      }
    });

    Vue.prototype.translate = function(value, language) {
      if (!value) {
        return "";
      }

      if (typeof value == "string") {
        try {
          value = JSON.parse(value);
        } catch (error) {
          return value;
        }
      }

      if (typeof value == "object") {

        //if language argument is present only translate to this language
        if(language){
          
          if (typeof value[language]  && value[language]) {
            return value[language];
          }
          return null
          
        }


        // let  theClientsLanguage = navigator.language;        
        
        // if(theClientsLanguage.includes('-')){
        //     theClientsLanguage = theClientsLanguage.split('-')[0];
        // }
        
        // console.log('Vue.config', defaultLanguage);
        
        // if (value[theClientsLanguage]) {
        //   return value[theClientsLanguage];
        // }

        var defaultLanguage = Vue.config.defaultLanguage;        
        if (value[defaultLanguage]) {
          return value[defaultLanguage];
        }
        
        var availableKeys = Object.keys(value);
        if (availableKeys.length > 0) {
          if (value[availableKeys[0]]) {
            return value[availableKeys[0]];
          }
        }

        // if (typeof value[theClientsLanguage] == "string" && value[theClientsLanguage]) {
        //   return "" + value[theClientsLanguage];
        // }
        // if (typeof value[defaultLanguage] == "string") {
        //   return "" + value[defaultLanguage];
        // }

        // var availableKeys = Object.keys(value);
        // if (availableKeys.length > 0) {
        //   if (typeof value[availableKeys[0]] == "string") {
        //     return "" + value[availableKeys[0]];
        //   }
        // }
      }
      return null;
    };
  }
};
