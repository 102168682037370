import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import Submissions from './views/Submissions.vue'
import Participate from './views/Participate.vue'
import SubmissionDetail from './views/SubmissionDetail.vue'
import Datenschutz from './views/Datenschutz'
import Teilnahmebedingungen from './views/Teilnahmebedingungen'
import Preise from './views/Preise'
import Impressum from './views/Impressum'
import EmailVerification from './views/EmailVerification.vue'
import VoteVerification from './views/VoteVerification.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'startpage',    
    component: Home,
  },  
  {
    path: '/entries/',
    name: 'submissions',    
    component: Submissions,
    children:[
      {
        path: 'search/:searchText?',
        name: 'search',    
        component: Submissions,
      },  
      {
        path: 'entry/:id',
        name: 'submission',    
        component: SubmissionDetail,
      }
    ]
  },  
  
  {
    path: '/participate',
    name: 'participate',
    component: Participate
  },  
  {
    path: '/impressum',
    name: 'impressum',
    component: Impressum
  },  
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: Datenschutz
  },  
  {
    path: '/teilnahmebedingungen',
    name: 'teilnahmebedingungen',
    component: Teilnahmebedingungen
  },  
  {
    path: '/preise',
    name: 'preise',
    component: Preise
  },  
  {
    path: '/email-verification/:id/:token',
    name: 'email-verification',
    component: EmailVerification
  },  
  {
    path: '/vote-verification/:id/:token',
    name: 'vote-verification',
    component: VoteVerification
  },  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
