<template>
  <div class="participate page">
    <div class="page-content">
      <section class="page-section">
        <div class="page-section-content">
          <validated-form
            v-if="!success"
            @submit="participate"
            class="participate-form"
            ref="participateForm"
          >
            <validated-input
              type="text"
              :label="$t('participate-input-label-name')"
              :placeholder="$t('participate-input-placeholder-name')"
              v-model="name"
              name="name"
              rules="required|string|min:3"
            />
            <validated-input
              type="text"
              :label="$t('participate-input-label-email')"
              :placeholder="$t('participate-input-placeholder-email')"
              v-model="email"
              name="email"
              rules="required|email"
            />
            <validated-input
              type="file"
              :label="$t('participate-input-label-file')"
              :placeholder="$t('participate-input-placeholder-file')"
              v-model="file"
              name="file"
              rules="required"
            />
            <validated-input
              v-if="false"
              type="textarea"
              :label="$t('participate-input-label-note')"
              :placeholder="$t('participate-input-placeholder-note')"
              v-model="note"
              name="note"
              rules="required|string|min:3"
            />
            <validated-input
              type="checkbox"
              :label="$t('participate-input-label-accept-terms')"
              v-model="accept"
              name="terms"
              rules="accepted"
            />
            <input type="submit" value="Submit" class="hidden-submit-button" />
            <div class="actions-container">
              <button-view
                class="participate-button"
                @click="participate"
                :label="$t('participate-button-label')"
                :processing="submitting"
              ></button-view>
            </div>
          </validated-form>
          <div
            class="success-message"
            v-if="success"
            v-html="$t('participate-success-message')"
          ></div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
function getBuffer(file) {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onload = (ev) => {
      resolve(ev.target.result);
    };
    reader.readAsArrayBuffer(file);
  });
}

export default {
  name: "Participate",
  components: {},
  data() {
    return {
      note: null,
      email: "",
      name: "",
      file: null,
      submitting: false,
      success: false,
      accept: false,
    };
  },
  mounted() {
    this.success = false;
    this.trackConversion();
  },
  computed: {},
  methods: {
    trackConversion() {
      
      let shouldTrack = window.localStorage.getItem("cookie-consent") === 'yes'      
      if (shouldTrack) {
        window._adftrack = Array.isArray(window._adftrack)
          ? window._adftrack
          : window._adftrack
          ? [window._adftrack]
          : [];
        window._adftrack.push({
          HttpHost: "track.adform.net",
          pm: 675270,
          divider: encodeURIComponent("|"),
          pagename: encodeURIComponent("ABC Lead"),
        });
        (function () {
          var s = document.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src =
            "https://s2.adform.net/banners/scripts/st/trackpoint-async.js";
          var x = document.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        })();
      }
    },
    reset() {
      this.file = null;
      this.email = null;
      this.name = null;
      this.note = null;
    },
    async participate() {
      
      // this.submitting = true;
      // let form = this.$refs.participateForm;
      // let isValid = form.validate();

      // if (isValid) {
      //   let file = this.file[0];
      //   let fileData = await getBuffer(file);

      //   try {
      //     let { body } = await this.$http.post(
      //       this.$config.apiUrl + "/api/create-uploadlink/",
      //       { type: file.type }
      //     );
      //     let uploadLink = body.url;
      //     let fileName = body.fileName;

      //     await this.$http.put(uploadLink, fileData, {
      //       headers: { "Content-Type": file.type },
      //     });

      //     let data = {
      //       file: { type: file.type, name: fileName },
      //       email: this.email,
      //       note: this.note,
      //       name: this.name,
      //     };

      //     await this.$http.post(this.$config.apiUrl + "/api/submit/", data);

      //     this.reset();
      //     this.success = true;
      //   } catch (error) {
      //     if (error.body == "EMAIL_EXISTS") {
      //       alert(this.$t("participate-email-exists-error"));
      //     } else {
      //       alert(this.$t("participate-error"));
      //     }

      //     console.log("submission error", error);
      //   }
      // }
      // this.submitting = false;
    },
  },
};
</script>

<style>
.participate-form {
  margin-right: 1rem; /* looks decentered otherwise */
}

.participate.page .success-message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 200;
  max-width: 600px;
  padding: 3rem;
  margin: 0 auto;
  line-height: 1.4;
  outline: 1px solid green;
}

.validated-form .validated-input.file .input-element-container {
  outline: 2px dashed grey;
  outline-offset: -8px;
  background: #f8f8f8;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}
.validated-form .validated-input.file.has-errors .input-element-container {
  outline: 2px dashed #ff9b9b;
}
.validated-form .validated-input.file .input-element-container input {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
  z-index: 200;
}

.validated-form .validated-input.file .input-element-container:hover {
  background: #005e36;
  background: #ffed00;
  background: var(--accent-color);
  color: white;
  outline: 2px dashed white;
}

.validated-form .validated-input.file .placeholder {
  font-size: 1em;
  text-align: center;
  display: block;
  width: 100%;
  padding: 10px;
}

.validated-form .validated-input.file .previews {
  width: 100%;
  padding: 10px;
  text-align: center;
}
.validated-form .validated-input.file .previews .preview-image {
  width: 100%;
  object-fit: contain;
}
.validated-form .actions-container {
  text-align: right;
}
.validated-form .actions-container .button-view {
  background-color: var(--accent-color);
  color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
  box-shadow: 1px 1px 4px hsla(20, 0%, 39%, 0.4);
}
.validated-form .actions-container .button-view:hover {
  color: var(--accent-color);
  background-color: #ffed00;
}
</style>
