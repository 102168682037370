<template>
  <div class="home page">
    <div class="page-content">
      <section class="page-section intro-section">
        <div class="intro text-content page-section-content">
          <h2>
            SPLASH IN<br />
            AND WIN!
          </h2>
        </div>
      </section>
      <section class="page-section submissions-section">
        <div class="page-section-content">
          <div class="submissions">
            <div
              class="submission"
              v-for="submission of filteredSubmissions"
              :key="submission.id"
              :class="submission.type"
            >
              <router-link
                class="background-link"
                :to="{ name: 'submission', params: { id: submission.id } }"
              ></router-link>
              <div
                class="media-container"
                :class="{
                  'is-placeholder': submission.thumbnail,
                  'has-video': submission.video,
                  [submission.type]: true,
                }"
              >
                <img class="video-indicator" v-if="submission.video" src="frontend/images/icons/play-orange.svg" />
                <img class="thumbnail" v-if="submission.thumbnail" :src="submission.thumbnail" />
                
              </div>
              <div class="submission-body">
                <div class="name" v-text="submission.name"></div>
                <blockquote                
                  class="note"
                  v-if="false && submission.note"
                  v-text="submission.note"
                ></blockquote>
              </div>
              <div class="submission-footer" v-if="false">
                <span
                  class="votings"
                  v-if="true || (submission.votes && submission.votes > 0)"
                  v-text="
                    (submission.votes ? submission.votes : 0) +
                    (submission.votes && submission.votes == 1
                      ? ' Vote'
                      : ' Votes')
                  "
                ></span>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    submissions() {
      return this.$store.getters.getSubmissions;
    },
    searchText() {
      return this.$route.params.searchText
        ? this.$route.params.searchText
        : null;
    },
    filteredSubmissions() {
      if (!this.submissions) {
        return [];
      }
      let results = [];
      let searchText = [this.searchText].join().trim().toLowerCase();

      for (let submission of this.submissions) {
        let validForText = true;
        let validForStatus = true;

        if (searchText && searchText.length > 1) {
          validForText = false;
          if (("" + submission.email).toLowerCase().includes(searchText)) {
            validForText = true;
          }
          if (("" + submission.name).toLowerCase().includes(searchText)) {
            validForText = true;
          }
        }
        if (validForText && validForStatus) {
          results.push(submission);
        }
      }
      return results;
    },
  },
  async mounted() {},
};
</script>
<style>
.home.page {
  padding-top: 0;
}

.page-container > .home.page  .page-section.submissions-section .page-section-content{
padding-top: 1rem;
}



.home.page .intro-section .page-section-content {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.home.page .intro-section h2 {
  font-family: "Anton", Arial, sans-serif;
  font-size: 76px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 1.1;
  color: #005e36;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
@media (max-width: 750px) {
  .home.page .intro-section h2 {
    font-size: 48px;
    letter-spacing: 2px;
  }
}


.background-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
}


.submissions {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: -1rem -5% 1rem;
}

.submissions .submission {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 2%;
  flex-basis: 46%;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
  border: 5px solid var(--accent-color);
  border-radius: 1rem;
  overflow: hidden;
}

@media (min-width: 800px) {
  .submissions {
    margin: -1rem -2% 1rem;
  }

  .submissions .submission {
    margin: 3% 2%;
    flex-basis: 29.3%;
  }
}
@media (max-width: 600px) {
 
  .submissions .submission {    
    flex-basis: 100%;
  }
}
.submissions .submission .media-container {
  position: relative;
}
.submissions .submission .media-container img.thumbnail {
  width: 100%;
  height: auto;
}
.submissions .submission .media-container img.video-indicator{
  width: 50px;
  height: auto;
  position: absolute;
  right: 15px;
  top: 15px;


}
.submissions .submission .submission-footer {
  padding: 1rem;
  text-align: center;
  line-height: 1.4;
  display: flex;
  flex-direction: row;  
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
}
.submissions .submission .submission-body .name {
  text-align: center;  
  font-size: 0.8rem;
  font-family: "Anton", Arial, sans-serif;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  color: #005e36;
  
}
.submissions .submission .submission-footer .votings {
  
  margin-right: 0.3rem;
  font-size: 0.8rem;
}

.submissions .submission .submission-body {
  padding: 0.4rem;
  text-align: center;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #FFE200;
}
.submissions .submission .submission-body .note {
  flex: 1;
  margin-bottom: 0.5rem;
  font-style: italic;
  font-weight: 300;
}

.submissions .submission .submission-body .note {
  quotes: "\201c""\201d";
}
.submissions .submission .submission-body .note:before {
  content: open-quote;
}
.submissions .submission .submission-body .note:after {
  content: close-quote;
}
</style>