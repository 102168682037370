<template>
  <div class="multilingual-input input-view" :class="{'has-errors': combinedErrors.length > 0, 'stacked': !showAllLanguages}">            
  <div class="language-tools">
    <div class="language-selector" v-if="!showAllLanguages">
      <span class="language"  v-for="language of enabledLanguages" :key="'selector-'+language" @click="showLanguage(language)" :class="{'active': (language == activeLanguage)}">{{language}}</span>    
    </div>
    <span class="stack-label" v-if="showAllLanguages" @click="toggleShowAll" >{{'multilingual-input-stack-label'|translate}}</span>
    <i  class="stack-icon" :class="{'icofont-rounded-expand': !showAllLanguages, 'icofont-rounded-collapse': showAllLanguages}" @click="toggleShowAll"></i>
  </div>

  
  <div class="language-inputs"   v-if="localValue">    
    
    <validated-input       
      v-for="language of enabledLanguages" :key="'input-'+language" 
      :placeholder="placeholder"            
      :name="name"
      :type="type" 
      :rules="rules"
      :label="label +` - <span class='language'>${$t('language-name-'+language)}</span>`"
      :disabled="disabled"
      class="language-input"  
      :class="{'active': (language == activeLanguage)}"
      v-show="showAllLanguages || (language == activeLanguage)"
      v-model="localValue[language]" 
      :ref="'input-for-'+language"
    ></validated-input>
  </div>
  <div class="combined-validation-errors" v-if="combinedErrors && combinedErrors.length">
      <div
        class="validation-error"
        v-for="(error,errorIndex) of combinedErrors"
        :key="'error-' + errorIndex"
        v-text="error"
      ></div>
    </div>
    
  </div>
</template>

<script >



export default {
  name: "multilingual-input",
  props: ["placeholder", "value", "name", "type", "rules", "label", "disabled"],
  data: function() {
    return {
      localValue: null,
      errors: [],
      activeLanguage: null,
      showAllLanguages: false
    };
  },
  computed:{
    enabledLanguages(){
      return this.$store.getters.getEnabledLanguages
    },
    combinedErrors(){
      let combined = [];
      for(let langKey in this.errors ){
        let errorsOfLanguage = this.errors[langKey];
        for(let error of errorsOfLanguage){
          if(!combined.includes(error)){
            combined.push(error);
          }
        }
      }
      return combined;
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function(newValue, oldValue){
        if(!newValue && !oldValue){
          return;
        }
        if(newValue === undefined){
          newValue = {};
        }
        let stringifiedNewValue = JSON.stringify(newValue);
        let stringifiedOldValue = JSON.stringify(oldValue);


        if (stringifiedNewValue != stringifiedOldValue) {
          let bareNewValue = JSON.parse(stringifiedNewValue);
          
          for(let language of this.enabledLanguages){
            // console.log('bareNewValue[language]', typeof bareNewValue[language])
            if(!bareNewValue[language]){
              bareNewValue[language] = null;
            }
          }
          this.localValue = bareNewValue;
        }
      }
    },
    localValue: {
      immediate: true,
      deep: true,
      
      handler: function(newValue, oldValue){
        // console.log('this.localValue', this.localValue)
        let cleanedLocalValue = {}
        for(let languageKey in this.localValue){
          if(this.localValue[languageKey] !== null){
            cleanedLocalValue[languageKey] = this.localValue[languageKey];
          }
        }

        this.$emit("input", cleanedLocalValue);
      }
    },
    errors: {
      immediate: true,
      deep: true,
      
      handler: function(){
        // console.log('this.errors', this.errors)
        for(let languageKey in this.localValue ){
          // console.log('ref',this.$refs['input-for-' + languageKey][0])          
          if(!this.$refs['input-for-' + languageKey]){
            continue
          }
          
          this.$refs['input-for-' + languageKey][0].errors =  this.errors && this.errors[languageKey] ?  this.errors[languageKey] : [];
          
        }        
      }
    },
    
  },
  mounted() {
    this.activeLanguage = this.enabledLanguages[0];
  },
  methods: {
    toggleShowAll(){
      this.showAllLanguages = !this.showAllLanguages;
    },
    showLanguage(language){
      this.activeLanguage = language;
    },
    getDataForValidation(){
      return this.localValue;
    }
  }
};
</script>
