<template>
  <div
    id="app"
    class="app"
    :class="{ ['nav-level-' + $route.matched.length]: true }"
  >
  <cookie-consent></cookie-consent>
    <nav>
      <tabs-view>
        <a href="https://www.schartnerbombe.at" class="home-button">
          <img
            src="frontend/images/logo.svg"
            :alt="$t('page-header-image-alt-text')"
          />
        </a>
        <tabs-view-tab
          :label="$t('tabview-title-newest')"
          :to="{ name: 'startpage', params: {} }"
          :exact="true"
        ></tabs-view-tab>
        <tabs-view-tab
          :label="$t('tabview-title-preise')"
          
          :to="{ name: 'preise'}"
        ></tabs-view-tab>
        <tabs-view-tab
          v-if="false"
          :label="$t('tabview-title-participate')"
          :to="{ name: 'participate', params: {} }"
        ></tabs-view-tab>
        <div class="search-tool" :class="{ visible: searchVisible }">
          <i class="icofont-search" @click="toggleSearch"></i>
          <div class="search-input-container" v-if="searchVisible">
            <input type="search" @input="updateSearch" :value="searchText" />
          </div>
        </div>
      </tabs-view>
    </nav>
    <header class="page-header">
      <div class="header-image">
        <img
          src="frontend/images/headers/challenge-header-2021.jpg"
          :alt="$t('page-header-image-alt-text')"
        />        
        <video
        class="header-video"
              autoplay
              muted
              loop
              playsinline
              poster="frontend/images/headers/challenge-header-2021.jpg"
              preload="none"
            >
              <source src="frontend/images/headers/ABC21_Header_Promoseite.mp4" type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
        
      </div>
    </header>
    <div class="page-container">
      <router-view></router-view>
    </div>
    <footer class="page-footer">
      <div class="social-icons">
        <a class="facebook" target="_blank" href="https://www.facebook.com/SchartnerBombe">
        <img src="frontend/images/icons/facebook.svg" />
        </a>
        <a class="youtube" target="_blank" href="https://www.youtube.com/user/schartnerbombe2012">
        <img src="frontend/images/icons/youtube.svg" />
        </a>
      </div>
      <nav class="secondary-nav">
        <router-link :to="{ name: 'teilnahmebedingungen' }"
          >Teilnahmebedingungen</router-link
        >
        <router-link :to="{ name: 'impressum' }">Impressum</router-link>
        <router-link :to="{ name: 'preise' }">Preise</router-link>
        <router-link :to="{ name: 'participate', params: {} }"
          >Mitmachen</router-link
        >
      </nav>
    </footer>
  </div>
</template>

<script>
import CookieConsent from "./views/CookieConsent";
import TabsView from "../../backend/src/components/TabsView";
import TabsViewTab from "../../backend/src/components/TabsViewTab.vue";

export default {
  name: "App",
  data() {
    return {
      searchVisible: false,
    };
  },
  mounted() {
    this.$store.dispatch("loadSubmissions");
    this.searchVisible = this.searchText;
  },
  watch: {
    $route: function () {
      // this.searchVisible = this.searchText
    },
  },
  computed: {
    searchText() {
      return this.$route.params.searchText
        ? this.$route.params.searchText
        : null;
    },
  },
  components: { TabsView, TabsViewTab,CookieConsent },
  methods: {
    toggleSearch() {
      if (this.searchVisible) {
        this.searchVisible = false;
        if (this.$route.params.searchText) {
          this.$router.push({ name: "search", params: { searchText: null } });
        }
      } else {
        this.searchVisible = true;
      }
    },
    updateSearch(e) {
      if (this.$route.params.searchText != e.target.value) {
        this.$router.push({
          name: "search",
          params: { searchText: e.target.value ? e.target.value : null },
        });
      }
    },
  },
};
</script>


<style>
.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fdf9cd;
  position: relative;
  z-index: 100;
  flex: 1;
  background-image: url("/frontend/images/background-colour-splash.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 200px 100px;
  
  overflow: visible;
}

@media (max-width: 900px) {
  .app {
      background-size: 100vh;
      background-position: 100px 100px;
  }
  
}

.page-header {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
  z-index: 3000;
  border-bottom: 4px solid #ffe100;
}
.page-header .header-image {
  width: 100%;
  height: 100%;
  line-height: 0;
}
.page-header img {
  width: 100%;
  object-fit: cover;
  display: none;
}
.page-header video {
  width: 100%;
  
}

.tabs-view .home-button {
  position: absolute;
  left: 1rem;
  top: 0.1rem;
  width: 15%;
  min-width: 70px;
  max-width: 150px;
  display: flex;
  color: var(--accent-color);
  text-decoration: none;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.tabs-view .home-button img {
  width: 100%;
  height: auto;
}

@media (max-width: 500px) {
  .tabs-view .home-button {
    top: 0.5rem;
  }
}

.tabs-view {
  border-bottom: 1px solid var(--accent-color);
  border-bottom: 1px solid #ffc52e;

  box-shadow: 1px 1px 8px hsla(20, 0%, 39%, 0.1);
  position: relative;
  z-index: 4000;
  background-color: #ffc52e;
}

.tabs-view .tabs {
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}

.tabs-view .tabs .tab {
  margin: 0 0.1rem;
  position: relative;
}

.tabs-view .tabs .tab a {
  padding: 1.2rem 2rem 1rem;

  display: inline-block;
  font-size: 22px;

  background: none;
  text-transform: uppercase;
  text-decoration: none;

  color: #005e36;
  position: relative;
  z-index: 10;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: "Anton", Arial, sans-serif;
}

@media (max-width: 750px) {
  .tabs-view .tabs .tab a {
    font-size: 18px;
    padding: 0.9rem 1rem 0.7rem;
  }
  .tabs-view .tabs {
    justify-content: flex-end;
  }
}

@media (max-width: 500px) {
  .tabs-view .tabs {
    flex-direction: column;
    align-items: center;
  }
  .tabs-view .tabs {
    justify-content: flex-end;
    padding: 1rem 0 1rem;
  }
  .tabs-view .tabs .tab a {
    font-size: 16px;
  }
}

.tabs-view .tabs .tab.router-link-active a {
  color: var(--accent-color);
  font-weight: 600;
}
.tabs-view .tabs .tab.router-link-active::after {
  content: " ";
  position: absolute;
  bottom: -1px;
  left: 10%;
  right: 10%;
  height: 1px;
  border-bottom: 2px solid var(--accent-color);
}
.page-container > .page .page-section .page-section-content {
  padding: 3rem;
  max-width: 900px;
  margin: 0 auto;
}

.page-container {
  position: relative;
  z-index: 100;
  flex: 1;
}
.nav-level-2 > .page-container {
  overflow: hidden;
}
.nav-level-2 > .page-container > .page > .page-content {
  visibility: hidden;
  position: absolute;
}

.page-footer {
  padding: 1.5rem 2rem 4rem;
}

.page-footer .secondary-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.6;
  flex-wrap: wrap;
}
.page-footer .secondary-nav a {
  font-size: 1.1rem;
  color: var(--accent-color);
  text-decoration: none;
  font-weight: 400;
  padding: 0.1rem 1rem;
  text-transform: uppercase;
}
.page-footer .secondary-nav a:hover {
  text-decoration: underline;
}
@media (max-width: 900px) {
  .page-footer .secondary-nav a {
    font-size: 1rem;
  }
}
@media (max-width: 500px) {
  .page-footer .secondary-nav a {
    font-size: 0.8rem;
  }
}

.search-tool {
  position: relative;
  margin-top: 5px;
}
.search-tool.visible {
  box-shadow: 1px 1px 8px hsla(20, 0%, 39%, 0.15);
  background-color: transparent;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.search-tool i {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  z-index: 2000;
  height: calc(100% + 6px);
  position: relative;
  margin-top: -6px;
  cursor: pointer;
  color: #005e36;
  font-size: 1.4rem;
}

.search-tool.visible i {
  background-color: var(--accent-color);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  color: white;
}

.search-input-container {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--accent-color);
  padding: 0.5rem;
  box-shadow: 1px 1px 8px hsla(20, 0%, 39%, 0.15);
  border-radius: 5px;
  border-top-right-radius: 0;
  z-index: 20;
}
.search-input-container input {
  border: 1px solid #ddd;
  width: 10rem;
}

@media (max-width: 800px) {
  .search-tool i {
    font-size: 1.2rem;
  }
}

@media (max-width: 500px) {
  .search-tool i {
    min-height: 2rem;
  }
  .search-tool {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
  }
}

.social-icons {
display: flex;
align-items: center;
justify-content: center;
padding-top: 3rem;
padding-bottom: 1.7rem;
}
.social-icons img{
  width: 80px;
  height: auto;
  margin: 0 0.3rem;
}
.social-icons .youtube img{
  width: 84px;
  margin-top: -2px;
  
}
</style>
