
<template>
  <div class="submission-detail page">
    <div class="page-content">
      <section class="page-section submission-section">
        <div class="page-section-content">
          <div class="submission" v-if="submission" :class="submission.type">
            <div class="submission-header text-content"></div>

            <div class="submission-body">
              <div
                class="media-container"
                :class="{
                  'is-placeholder': submission.thumbnail,
                  'has-video': submission.video,
                  [submission.type]: true,
                }"
              >
                <img
                  v-if="isImage"
                  class="thumbnail"
                  :src="submission.fullsize"
                />
                <vue-core-video-player
                  v-if="isVideo"
                  :src="submission.video"
                  :cover="submission.thumbnail"
                ></vue-core-video-player>
              </div>
              <div class="share-section">
                <iframe :src="'https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fchallenge.schartnerbombe.at%2Fentries%2Fentry%2F${submission.id}&layout=button_count&size=large&width=88&height=28&appId'" width="88" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </div>
              <h2 class="name" v-text="submission.name"></h2>
                <div class="date">{{ submission.date | date }}</div>
              <div class="submission-footer">
                <span
                  class="votings"
                  v-if="false || (submission.votes && submission.votes > 0)"
                  v-text="(submission.votes ? submission.votes : 0) + ((submission.votes && submission.votes == 1) ? ' Vote' : ' Votes' )"
                ></span>
              </div>
              

              <voting-widget v-if="false" :submission="submission"></voting-widget>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VotingWidget from "./VotingWidget.vue";

export default {
  components: { VotingWidget },
  data() {
    return {};
  },

  methods: {},
  computed: {
    submission() {
      return this.$store.getters.getSubmissionById(this.$route.params.id);
    },
    isVideo() {
      return this.submission.type == "video";
    },
    isImage() {
      return this.submission.type == "image";
    },
  },
  async mounted() {
    if (!this.$route.params.id) {
      return this.$router.push("/");
    }
  },
};
</script>
<style>
.submission-section .submission {
  position: relative;
}
.submission-section .submission .submission-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.submission-section .submission .submission-header h2 {
  padding-top: 0;
}

.submission-section .submission .media-container {
  max-width: 400px;
  margin: 0 auto 1rem;
}

.submission-section .submission .share-section {
  text-align: center;
  margin: 0 0 2rem;
}
.submission-section .submission .media-container img.thumbnail {
  width: 100%;
  height: auto;
}
.submission-section .submission .submission-body .note {
  flex: 1;
  margin-bottom: 1rem;
  font-style: italic;
  font-weight: 300;
  font-size: 2rem;
  text-align: center;
}

.submission-section .submission .submission-body .note {
  quotes: "\201c""\201d";
}
.submission-section .submission .submission-body .note:before {
  content: open-quote;
}
.submission-section .submission .submission-body .note:after {
  content: close-quote;
}

.submission-section  .submission-body .name {
  text-align: center;
  font-size: 1.4rem;
  
  margin-bottom: 0.5rem;
  font-family: "Anton", Arial, sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.1;
  color: #005e36;  
}
.submission-section .submission-body .vcp-container,
.submission-section .submission-body .vcp-container video {
  line-height: 0;
}
.submission-section .submission-body .date {
  text-align: center;
  font-size: 1rem;
  font-weight: 200;
  margin-bottom: 2rem;
}
.submission-section .submission .submission-footer {
  padding: 1rem;
  text-align: center;
  line-height: 1.4;
  display: flex;
  flex-direction: row;
  
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
}
.submission-section .submission .submission-footer .date {
  text-align: right;
  margin-right: 0.3rem;
  font-size: 0.8rem;
}
.submission-section .submission .submission-footer .votings {
  
  margin-right: 0.3rem;
  font-size: 0.8rem;
}
</style>