<template>
  <button class="button button-view" :type="type" :name="name" @click.stop.prevent="clicked" :class="{'icon-only': !label && !processingLabel, 'disabled': disabled }">        
    <a class="background-link" v-if="href" :href="href" ></a>  
    <router-link class="background-link" v-if="to" :to="to"  ></router-link>      
    <div class="processing-icon"  v-if="processing" ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <span class="processing-label label"  v-if="processing && processingLabel" v-text="processingLabel" ></span>  
    <span class="icon"  v-if="icon" :class="icon" ></span>  

    <span class="label" v-if="label && !(processing && processingLabel) && !href" v-text="label"></span>  
    <a class="label" v-if="label && !(processing && processingLabel) && href" v-text="label" :href="href"></a>  
    
  </button>
</template>

<script >

export default {
  name: "button-view",
  props: ["label", "icon", "name", "type", "processing", "processing-label", "href" , "to", "disabled"],
  data: function() {
    return {
    };
  },
  watch: {
    
  },
  mounted() {},
  methods: {
    clicked(e){
      if(this.disabled){
        return;
      }
      if(this.href){
        window.location.replace(this.href);
        return;
      }
      if(this.to){
        this.$router.push(this.to);
        return;
      }
      this.$emit('click',e);
    }
    
  }
};
</script>
<style >

.processing-icon {
  color: #fff;
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  margin-bottom: -3px;
}
.processing-icon div {
  transform-origin: 8px 8px;
  animation: processing-icon 1.2s linear infinite;
}
.processing-icon div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: 7.5px;
  width: 1px;
  height: 4px;
  border-radius: 20%;
  
  background: #fff;    
}
.button-view:hover .processing-icon div:after {
  background: var(--accent-color);
}
.processing-icon div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.processing-icon div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.processing-icon div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.processing-icon div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.processing-icon div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.processing-icon div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.processing-icon div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.processing-icon div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.processing-icon div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.processing-icon div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.processing-icon div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.processing-icon div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes processing-icon {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


</style>