<template>
  <div class="tabs-view-tab">    
    <router-link class="tab" :to="to" tag="div" :exact="exact">
      <a>
        <span class="label" v-text="label"></span>
      </a>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "tabs-view-tab",
  props:['label', "to", "exact"]  
};
</script>
