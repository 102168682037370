<template>
  <div class="voting-widget">
    <button-view
      v-if="!open"
      class="open-button"
      icon="icofont-thumbs-up"
      :label="$t('voting-widget-vote-label')"
      @click="openIt"
    ></button-view>

    
      <validated-form
        v-if="open && !success"
        @submit="vote"
        class="vote-form"
        ref="voteForm"
      >
        <validated-input
          type="text"
          :label="$t('vote-form-input-label-email')"
          :placeholder="$t('vote-form-input-placeholder-email')"
          v-model="email"
          name="email"
          rules="required|email"
        />
        <input type="submit" value="Submit" class="hidden-submit-button" />
        <div class="actions-container">
          <button-view
            class="vote-button"
            @click="vote"
            :label="$t('vote-form-vote-button-label')"
            :processing="voting"
          ></button-view>
        </div>
      </validated-form>
      
      <div
        class="success-message"
        v-if="success"
        v-html="$t('vote-success-message')"
      ></div>
    
  </div>
</template>

<script>
import ButtonView from "../../../backend/src/elements/ButtonView.vue";

export default {
  components: { ButtonView },
  name: "voting-widget",
  props: ["submission"],
  data() {
    return {
      open: false,
      success: false,
      voting: false,
      email: "",
    };
  },
  methods: {
    openIt() {
      this.open = true;
    },
    reset() {},
    async vote() {
      this.voting = true;
      try {
        let data = {
          email: this.email,
          submissionId: this.submission.id
        };

        await this.$http.post(this.$config.apiUrl + "/api/vote/", data);

        this.reset();
        this.success = true;
      } catch (error) {
        if (error.body == "EMAIL_ALREADY_VOTED") {
          alert(this.$t("vote-email-already-voted-error"));
        } else {
          alert(this.$t("vote-error"));
        }

        console.log("submission error", error);
      }
      this.voting = false;
    },
  },
  computed: {},
  async mounted() {},
};
</script>
<style>
.voting-widget .button-view.open-button {
  width: 100%;
  color: white;
  background-color: var(--accent-color);
  font-size: 1.2rem;
  max-width: 400px;
  margin: 3rem auto;
  display: block;
}
.voting-widget .vote-form{
  width: 100%;
  color: white;
  background-color: var(--accent-color);
  font-size: 1.2rem;
  max-width: 400px;
  margin: 3rem auto;
  display: block;
  border-radius: 1.6rem;
  padding: 1.5em 2rem;
}
.voting-widget .vote-form label .label {
  color: white;
}
.voting-widget .vote-form .button-view {
  background-color: white;
  color: var(--accent-color);
  width: 100%;
}
.voting-widget .vote-form .button-view .processing-icon div:after {
  background-color: var(--accent-color);
}
.voting-widget .vote-form .button-view:hover {
  color: black;
}


.voting-widget .success-message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 200;
  max-width: 400px;
  padding: 3rem;
  margin: 0 auto;
  line-height: 1.4;
  outline: 1px solid green;
}

</style>
