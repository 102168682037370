<template>
  <div class="vote-verifcation page">
    <div class="page-content">
      <section class="page-section">
        <div class="page-section-content">
          <div class="verifying-message" v-if="verifying">
            <spinner :spinning="true"></spinner>
            <div
              class="label"
              v-text="$t('vote-verifcation-verifying-message')"
            ></div>
          </div>
          <div
            class="error-message"
            v-if="!verifying && !success"
            v-html="$t('vote-verifcation-error-message')"
          ></div>
          <div
            class="success-message"
            v-if="!verifying && success"
            v-html="$t('vote-verifcation-success-message')"
          ></div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "VoteVerification",
  components: {},
  data() {
    return {
      success: false,
      verifying: false,
    };
  },
  mounted() {
    this.verify();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    async verify() {
      this.verifying = true;
      try {
        await this.$http.post(this.$config.apiUrl + "/api/vote-verification/", {
          id: this.id,
          token: this.token,
        });
        this.success = true;
      } catch (error) {
        console.log("submission error", error);
      }
      this.verifying = false;
    },
  },
};
</script>

<style>
.vote-verifcation .verifying-message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 200;
  max-width: 600px;
  padding: 3rem;
  margin: 0 auto;
  line-height: 1.4;
}
.vote-verifcation .success-message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 200;
  max-width: 600px;
  padding: 3rem;
  margin: 0 auto;
  line-height: 1.4;
  outline: 1px solid green;
}

.vote-verifcation .error-message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 200;
  max-width: 600px;
  padding: 3rem;
  margin: 0 auto;
  line-height: 1.4;
  outline: 1px solid red;
}
</style>
