<template>
  <div class="home page">
    <div class="page-content">
      <section class="page-section submissions-section">
        <div class="page-section-content">
          <div class="submissions">
            <div
              class="submission"
              v-for="submission of filteredSubmissions"
              :key="submission.id"
              :class="submission.type"
            >
              <router-link
                class="background-link"
                :to="{ name: 'submission', params: { id: submission.id } }"
              ></router-link>
              <div
                class="media-container"
                :class="{
                  'is-placeholder': submission.thumbnail,
                  'has-video': submission.video,
                  [submission.type]: true,
                }"
              >
                <img v-if="submission.thumbnail" :src="submission.thumbnail" />                
              </div>
              <div class="submission-body">
                <blockquote
                  class="note"
                  v-if="submission.note"
                  v-text="submission.note"
                ></blockquote>
              </div>
              <div class="submission-footer">
                <span
                  class="votings"
                  v-if="true || (submission.votes && submission.votes > 0)"                  
                  v-text="(submission.votes ? submission.votes : 0) + ((submission.votes && submission.votes == 1) ? ' Vote' : ' Votes' )"
                ></span>
                <div class="name" v-text="submission.name"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="page-container second-level">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    submissions() {
      return this.$store.getters.getSubmissions;
    },
    searchText() {
      return this.$route.params.searchText
        ? this.$route.params.searchText
        : null;
    },
    filteredSubmissions() {
      if (!this.submissions) {
        return [];
      }
      let results = [];
      let searchText = [this.searchText].join().trim().toLowerCase();

      for (let submission of this.submissions) {
        let validForText = true;
        let validForStatus = true;

        if (searchText && searchText.length > 1) {
          validForText = false;
          if (("" + submission.email).toLowerCase().includes(searchText)) {
            validForText = true;
          }
          if (("" + submission.name).toLowerCase().includes(searchText)) {
            validForText = true;
          }
        }
        if (validForText && validForStatus) {
          results.push(submission);
        }
      }
      return results;
    },
  },
  async mounted() {},
};
</script>
<style>

</style>