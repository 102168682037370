<template>
  <div class="tabs-view">
    <div class="tabs" ><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: "tabs-view",
  computed: {
    
  },
  mounted: async function() {

  },
  methods: {

  }
};
</script>
